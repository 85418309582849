import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';

function Login({ onLogin }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password, action: 'login' })
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    onLogin(data.token, data.userId, data.role);  // Pass the token, userId, and role to the App component
                    navigate(data.dashboard_url);  // Redirect the user to their dashboard
                } else {
                    alert("Login failed! " + (data.message || ""));
                }
            } else {
                alert("Login failed! Server returned an error.");
            }
        } catch (error) {
            console.error("Login error:", error);
            alert("An error occurred while logging in. Please try again.");
        }
    };

    return (
        <div className="login">
            <form className="login-form" onSubmit={handleLogin}>
                <img src="./echolink-vertical-white.png" alt="Logo" />
                <div className="login-form-container">
                    <h2>Login</h2>
                    {/* Material UI TextField for Username */}
                    <TextField
                        id="username"
                        label="Username"
                        variant="outlined"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        InputLabelProps={{
                            style: {
                              color: 'white',  // Make the label white
                            },
                        }}
                    />
                    {/* Material UI TextField for Password */}
                    <TextField
                        id="password"
                        label="Password"  // Use label for consistency
                        type="password"
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputLabelProps={{
                            style: {
                              color: 'white',  // Make the label white
                            },
                        }}
                    />
                    <p>
                        <Link to="/">Forgot Password?</Link>
                    </p>
                    <button type="submit">Enter</button>
                </div>
            </form>
        </div>
    );
}

export default Login;

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Navbar.css';
import logo from "../img/echolink-horizontal-white.png";

const Navbar = ({ isAuthenticated, onLogout }) => {
    const [userId, setUserId] = useState(null);
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location
    const userRole = localStorage.getItem('userRole'); // Get the role from localStorage

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            setUserId(storedUserId);
        } else {
            console.error("User ID not found in localStorage");
        }
    }, []);

    const handleLogout = () => {
        onLogout();
        navigate('/');  // Redirect to login page after logout
    };

    // Helper function to check if the current path includes the link path
    const isActive = (path) => location.pathname.includes(path);

    // Extract clientId from the URL manually using regex
    const clientIdMatch = location.pathname.match(/\/client\/(\d+)/);
    const clientId = clientIdMatch ? clientIdMatch[1] : null;

    // Check if the user is on a client feedback summary page
    const isOnFeedbackPage = location.pathname.includes('feedbacks');

    return (
        isAuthenticated && userId && ( // Ensure userId is not null
            <nav className="navbar">
                <ul className="navbar-links">
                    <img src={logo} alt="logo" />
                    <div className='navbar-links-right'>
                        {userRole === 'admin' && (
                            <Link
                                to={`/admin/dashboard`}
                                className={isActive('/admin/dashboard') ? 'active-link' : ''}
                            >
                                Clients
                            </Link>
                        )}
                        <Link
                            to={`/edit-account/${userRole === 'admin' ? 'user' : 'client'}/${userId}`}
                            className={isActive(`/edit-account`) ? 'active-link' : ''}
                        >
                            Account
                        </Link>
                        
                        {/* Conditionally render the "Submit Feedback" button when on the feedback summary page */}
                        {isOnFeedbackPage && clientId && (
                            <Link 
                                to={`/client/${clientId}/submit-feedback`}  // Navigate to the submit feedback page using clientId
                                className="navbar-button navbar-submit-button"
                            >
                                Share Submit Feedback Page
                            </Link>
                        )}
                    </div>
                </ul>
                <div className="navbar-account">
                    <button className="logout-button" onClick={handleLogout}>Logout</button>
                </div>
            </nav>
        )
    );
};

export default Navbar;

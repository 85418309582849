import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FeedbackForm from './FeedbackForm';
import './ClientFeedbackFormPage.css'; // Import CSS for custom styling
import TextField from '@mui/material/TextField';

function ClientFeedbackFormPage() {
    const { clientId } = useParams();  // Extract clientId from URL
    const [clientDetails, setClientDetails] = useState(null);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);  // To handle success message
    const [loading, setLoading] = useState(true);  // To handle loading state

    useEffect(() => {
        const fetchClientDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clients.php?id=${clientId}`);
                const data = await response.json();

                if (response.ok && data) {
                    if (data.name) {
                        setClientDetails(data);  // Handle single client returned
                    } else if (data.records && data.records.length > 0) {
                        const client = data.records.find(client => client.id === parseInt(clientId, 10));
                        setClientDetails(client || {});
                    } else {
                        setError("Client details not found.");
                    }
                } else {
                    setError("Failed to load client details.");
                }
            } catch (error) {
                console.error("Error fetching client details:", error);
                setError("An error occurred while fetching client details.");
            } finally {
                setLoading(false);
            }
        };

        fetchClientDetails();
    }, [clientId]);

    const handleNewFeedback = () => {
        setSuccess(true);  // Show success message when feedback is successfully submitted
        setTimeout(() => setSuccess(false), 3000);  // Hide success message after 3 seconds
    };

    const backgroundImage = clientDetails?.background ? `${process.env.REACT_APP_BACKEND_URL}/h${clientDetails.background.replace('../', '')}` : '';
    const logoImage = clientDetails?.logo ? `${process.env.REACT_APP_BACKEND_URL}/${clientDetails.logo.replace('../', '')}` : '';

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    if (!clientDetails) {
        return <p>Client details not found.</p>;
    }

    return (
        <div
            className="feedback-page-container"
            style={{
                backgroundImage: backgroundImage ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})` : 'none',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div className="left-content">
                {logoImage && (
                    <div className="client-logo-container">
                        <img src={logoImage} alt={`${clientDetails.name} Logo`} className="client-logo" />
                    </div>
                )}
                <div className="feedback-form-header">
                    <h2>Submit Your Feedback for {clientDetails.name}</h2>
                    <p>We value your feedback. Please let us know how we can improve.</p>
                </div>
            </div>
            <FeedbackForm clientId={clientId} onNewFeedback={handleNewFeedback} />  {/* Pass clientId to FeedbackForm */}
            {success && <p className="success-message">Thank you for your feedback!</p>}  {/* Show success message */}
        </div>
    );
}

export default ClientFeedbackFormPage;
